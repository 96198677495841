import React from "react";
import Navbar from "../Navbar";

import TeamSection from "./sections/TeamSection";
import ProductsSection from "./sections/ProductsSection";
import ServicesSection from "./sections/ServicesSection";
import ContactUsSection from "./sections/ContactUsSection";
import PartnersSection from "./sections/PartnersSection";
import AnimationSection from "./sections/AnimationSection";

import styles from "./index.module.scss";

const PungoPage: React.FC = () => {
  return (
    <div>
      <div className={styles.navbar}>
        <Navbar />
      </div>
      <AnimationSection />
      <ProductsSection />
      <ServicesSection />
      <TeamSection />
      <PartnersSection />
      <ContactUsSection />
    </div>
  );
};

export default PungoPage;
