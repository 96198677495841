import React from "react";
import PungoClickable from "../PungoClickable";

import styles from "./index.module.scss";

interface PungoProductCardProps {
  title: string;
  image: string;
  description: React.ReactNode;
  link: string;
}

const PungoProductCard: React.FC<PungoProductCardProps> = (props) => {
  const { title, image, description, link } = props;

  return (
    <PungoClickable link={link}>
      <div className={styles.container}>
        <img src={title} alt="Product Logo" className={styles.title} />
        <div className={styles.content}>
          <img src={image} alt="Animation Logo" className={styles.icon} />
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </PungoClickable>
  );
};

export default PungoProductCard;
