import React, { useEffect, useState } from "react";
import text from "../../../../assets/PungoIcons/textAnimated.gif";
import englishText from "../../../../assets/PungoIcons/englishTextAnimated.gif";
import monitor from "../../../../assets/PungoIcons/MonitorAnimation.gif";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const AnimationSection: React.FC = () => {
  const { i18n } = useTranslation();
  const isSpanishSelected = i18n.language === "es";

  return (
    <section className={styles.container} id="start">
      <img
        className={styles.gif}
        src={isSpanishSelected ? text : englishText}
        alt="Text animation"
      />
      <img className={styles.gif} src={monitor} alt="Monitor animation" />
    </section>
  );
};

export default AnimationSection;
